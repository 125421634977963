<template>
  <section class="zoom">
    <div class="movil mx-auto" v-if="anchoPantalla < 992">
      <h4 class="mb-4">
        <b>
          Tamaño de caracter:
        </b>
      </h4>
      <div class="grilla">
        <button @click="reducirZoom">
          -
        </button>
        <h4 class="text-center">
          {{ zoom }}%
        </h4>
        <button @click="aumentarZoom">
          +
        </button>
      </div>
    </div>
    <div class="escritorio" v-else>
      <h3>A</h3>
      <div>
          <input type="range" :min="90" :max="110" v-model="zoom" class="slider" id="myRange">
      </div>
      <h4>A</h4>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Zoom',
  created () {
    const app = document.getElementById('app')
    const zoom = this.$store.state.zoom
    app.style = `zoom: ${zoom}%;`
  },
  data () {
    return {
      zoom: this.$store.state.zoom
    }
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
  methods: {
    aumentarZoom () {
      const app = document.getElementById('app')
      let zoom = this.$store.state.zoom
      zoom++
      this.$store.commit('cambiarZoom', zoom)
      app.style = `zoom: ${zoom}%;`
    },
    reducirZoom () {
      const app = document.getElementById('app')
      let zoom = this.$store.state.zoom
      zoom--
      this.$store.commit('cambiarZoom', zoom)
      app.style = `zoom: ${zoom}%;`
    },
  },
  watch: {
    zoom: {
      handler (val) {
        const app = document.getElementById('app')
        this.$store.commit('cambiarZoom', this.zoom)
        app.style = `zoom: ${this.zoom}%;`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom {
  .movil {
    max-width: 450px;
    color: #f2f2f2;

    .grilla {
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      align-items: center;

      button {
        border: none;
        background: transparent;
        font-size: 2rem;
        color: #f2f2f2;
      }
    }
  }
}
</style>