<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">Votaciones</h2>

          <b-list-group>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/votos-coalicion"
              active
            >
              Votos en Coalición
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/casillas-electorales"
            >
              Casillas Electorales
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/participacion-ciudadana"
            >
              Participación Ciudadana
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/lista-nominal"
            >
              Lista Nominal
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Votaciones
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Votos en Coalición
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Votos en Coalición
          </h2>

          <b-embed
            type="video"
            aspect="16by9"
            controls
          >
            <source
              src="https://ayuda.ine.mx/2021/informate/assets/video/Votos_en_Coalici%C3%B3n.mp4"
              type="video/mp4"
            >
          </b-embed>

          <div class="row my-5">
            <div class="col-12">
              <h4>
                1. Es la unión de dos o más Partidos Políticos para postular a una misma Candidatura
                por el principio de Mayoría Relativa para el Proceso Electoral correspondiente.
              </h4>

              <p>
                <b>
                  Las ciudadanas y los ciudadanos que elijan votar
                  por una Coalición deben realizar lo siguiente:
                </b>
              </p>
            </div>

            <div class="col-12 mb-3 col-lg-6">
              <ul class="mb-3">
                <li>
                  Marcar en la boleta electoral todos los
                  Partidos Políticos que integran la Coalición.
                </li>
              </ul>

              <img
                class="mx-auto d-block img-fluid"
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-1.svg"
              />
            </div>

            <div class="col-12 mb-3 col-lg-6">
              <ul class="mb-3">
                <li>
                  Marcar en la boleta electoral uno o
                  más Partidos Políticos que integran la Coalición.
                </li>
              </ul>

              <img
                class="mx-auto d-block img-fluid"
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-2.svg"
              />
            </div>

            <div class="col-12">
              <p>
                La publicación de estos resultados están en el
                sitio PREP, por el reporte Votos por Distritos:
              </p>

              <p>
                En el menú "Consulta de votación en Detalle por",
                selecciona Circunscripción, Entidad y el Distrito que deseas consultar.
              </p>
            </div>
          </div>

          <div class="row p-5 seccion-color">
            <div class="col-12 mb-3">
              <h4>
                2. Suma de votos por Candidatura en Coalición
              </h4>

              <p>
                El total de votos de una Candidatura en Coalición es la suma
                de votos obtenidos por combinación, más los votos obtenidos individualmente.
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <h5>
                Votos obtenidos por combinación de Partidos Políticos en Coalición
              </h5>

              <p>
                Si en una boleta se marca una o más opciones
                de los Partidos Políticos coaligados, son considerados
                votos válidos para la Candidatura postulada y cuenta como un solo voto.
              </p>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <h5>
                Votos obtenidos individualmente por los Partidos Políticos que integran la Coalición
              </h5>

              <p>
                Si en la boleta electoral se marca un solo Partido Político integrante
                de la Coalición o Candidatura, es un voto válido, el cual cuenta para
                la Coalición o Candidatura del Partido Político seleccionado, por lo
                tanto, tiene todos los efectos establecidos en la Ley.
              </p>
            </div>

            <div class="col-12 mb-3">
              <img
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-3.png"
                alt="Ejemplo Coalición 3"
                class="img-fluid mx-auto d-block mb-3"
              >
              <img
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-4.png"
                alt="Ejemplo Coalición 4"
                class="img-fluid mx-auto d-block mb-3"
              >
            </div>
          </div>

          <div class="row my-5">
            <div class="col-12 mb-4">
              <h4>
                3. Distribución de votos para los Partidos Políticos en Coalición
              </h4>

              <p>
                Es la asignación equitativa de los votos para cada Partido Político
                que se encuentran en Coalición, o bien, entre sus posibles combinaciones.
              </p>

              <p>
                <b>
                  ¿Cómo se hace la distribución?
                </b>
              </p>

              <p>
                La suma de los votos obtenidos en Coalición se divide entre el
                número de Partidos Políticos que integran la combinación.
              </p>

              <p>
                Al resultado de esta división se le suma el total de los votos
                obtenidos individualmente por los Partidos Políticos de la Coalición.
              </p>

              <p>
                Los votos sobrantes se distribuyen entre los Partidos Políticos
                que han obtenido el mayor número de votos de manera individual.
                Se asigna un voto a tantos Partidos Políticos como sea necesario.
              </p>

              <p>
                En caso de que haya empate entre dos Partidos Políticos se le otorga
                el voto sobrante al Partido Político con mayor antigüedad de registro.
              </p>
            </div>

            <div class="mb-4 col-12 col-lg-6">
              <img
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-5.jpg"
                alt=" Coalicion 5"
                class="img-fluid d-block mx-auto"
              >
            </div>
            <div class="mb-4 col-12 col-lg-6">
              <img
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/coalicion-6.jpg"
                alt=" Coalición 6"
                class="img-fluid d-block mx-auto"
              >
            </div>

            <div class="col-12 mb-4">
              <p>
                <b>Nota: </b>
                Lo anterior se realizará con cada una de las combinaciones de una Coalición.
              </p>

              <div class="grid">
                <img
                  src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/c-verde.svg"
                >

                <p>
                  Número de Partidos Políticos
                </p>
              </div>
              <div class="grid">
                <img
                  src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/c-morado.svg"
                >

                <p>
                  Votos obtenidos en Coalición
                </p>
              </div>
              <div class="grid">
                <img
                  src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/c-rosa.svg"
                >

                <p>
                  Votos
                </p>
              </div>
              <div class="grid">
                <img
                  src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/c-morado-4.svg"
                >

                <p>
                  Votos Sobrantes
                </p>
              </div>

              <img
                src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/tabla.png"
                class="d-block img-fluid mx-auto mb-3"
              >

              <p class="mb-5">
                <sup>*</sup>
                El resultado que se obtiene para cada Partido Político.
              </p>

              <p class="text-center mb-5">
                <b>
                  Referencias:
                </b>
                <br />
                · Artículo 311, inciso c) Ley General de Instituciones y Procedimientos Electorales.
              </p>

              <a
                href="https://ayuda.ine.mx/2021/informate/assets/docs/Votaciones/Votos_en_Coalicion.pdf"
                target="_blank"
                class="btn btn-primary mx-auto d-block"
              >
                Descarga Infografía
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}
</style>
