<template>
  <section class="centro-ayuda">
    <div class="vista-escritorio" v-if="anchoPantalla >= 992">
      <cabecera />

      <h1 class="text-center my-5">
        Infórmate | Centro de Ayuda PREP
      </h1>

      <section class="grid-menu container" style="background: transparent;">
        <div>
          <router-link to="/centro-de-ayuda">
            Inicio
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/elecciones">
            Elecciones
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/votaciones/votos-coalicion">
            Votaciones
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/normatividad">
            Normatividad
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/prep/conoce/que-es">
            PREP
          </router-link>
        </div>
        <div>
          <a href="https://ayuda.ine.mx/2021/informate/glosario" target="_blank">
            Glosario
          </a>
        </div>
      </section>

      <slot />

      <footer class="pie-de-pagina">
        <nav>
          <ul>
            <li>
              <p>
                Este sitio se visualiza mejor en las versiones
                más recientes de los navegadores web.
              </p>
            </li>
            <li>
              <p> Instituto Estatal Electoral de Baja California Sur</p>
            </li>
            <li>
              <button v-b-modal.AvisoPrivacidadMovilModal>
                    Aviso de privacidad
                </button>
            </li>
          </ul>
        </nav>
      </footer>
      <Compartir />
    </div>
    <LayoutMovil
      v-else
      extranjero
    >
      <h1 class="text-center my-5">
        Infórmate | Centro de Ayuda PREP y Cómputos
      </h1>

       <section class="grid-menu container" style="background: transparent;">
        <div>
          <router-link to="/centro-de-ayuda">
            Inicio
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/elecciones">
            Elecciones
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/votaciones/votos-coalicion">
            Votaciones
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/normatividad">
            Normatividad
          </router-link>
        </div>
        <div>
          <router-link to="/centro-de-ayuda/prep/conoce/que-es">
            PREP
          </router-link>
        </div>
        <div>
          <a href="https://ayuda.ine.mx/2021/informate/glosario" target="_blank">
            Glosario
          </a>
        </div>
      </section>

      <slot />
    </LayoutMovil>
  </section>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera.vue';
import LayoutMovil from '@/components/Movil/layouts/Layout.vue';
import Compartir from '@/components/Compartir.vue';

export default {
  name: 'CentroDeAyudaLayout',
  components: {
    Cabecera,
    Compartir,
    LayoutMovil,
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
};
</script>

<style lang="scss" scoped>
.vista-escritorio {
  background-color: #f4f4f4;

  .container {
    background-color: white;
  }

  svg {
    max-width: 320px;
  }
}

.btn {
  max-width: 350px;
  padding: 1.3rem;
  border-radius: 15px;
  border-color: transparent;
  background-color: #792342;
  box-shadow: transparent;
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.grid-menu {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
