<template>
  <!-- <header style="position:inherit !important;"> -->
  <header>
        <div>
            <div class="cabecera-opciones">
                <ul>
                    <li>
                        <font-awesome-icon icon="question-circle" />
                        <router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link>
                    </li>
                    <li>
                        <font-awesome-icon icon="info-circle" />
                        <router-link to="/centro-de-ayuda">
                          Centro de ayuda
                        </router-link>
                        <!-- <a href="https://ayuda.ine.mx/2018/PREP/" target="__blank">Centro de ayuda</a> -->
                    </li>
                    <li class="slidecontainer">
                        <zoom />
                    </li>
                    <li>
                        <modo-oscuro />
                    </li>
                </ul>
            </div>
            <div class="cabecera-titulo">
                <ul class="row mx-0">
                    <li class="col logo-ieebcs">
                        <div>
                            <img src="@/assets/logo-ieebcs.png" alt="logo IEEBCS" v-if="!esOscuro">
                            <img src="@/assets/ieebcs.png" alt="logo IEEBCS" v-else>
                        </div>
                    </li>
                    <li class="col-7">
                        <div>
                            <h1>Programa de Resultados
                              <span>E</span>lectorales Preliminares 2021
                            </h1>
                            <h5>Elecciones Estatales de <span>Baja California Sur</span></h5>
                        </div>
                    </li>
                    <li class="col logo-prep">
                        <div>
                            <img
                              v-if="!esOscuro"
                              src="@/assets/generales/prep-logo.png"
                              alt="logo prep generales"
                            >
                            <img
                              v-else
                              src="@/assets/PrepLogoFooter.png"
                              alt="logo prep generales"
                            >
                        </div>
                    </li>
                </ul>
            </div>
            <div class="barra-de-navegacion">
                <nav>
                    <ul>
                        <li>
                            <div>
                              <div>
                                <router-link :to="cambiarVista('G')">
                                <font-awesome-icon icon="home" />
                                Inicio
                                </router-link>
                              </div>
                            </div>

                        </li>
                        <li>
                            <div :class="{ 'activo': eleccion === 'G' }">
                              <router-link :to="cambiarVista('G')">Gubernatura</router-link>
                            </div>
                        </li>
                        <li>
                            <div
                              :class="{
                                'activo': eleccion === 'D' || esRepresentacionProporcional
                              }"
                            >
                                <router-link :to="cambiarVista('D')">Diputaciones</router-link>
                            </div>
                        </li>
                        <li>
                            <div :class="{ 'activo': eleccion === 'A' }">
                                <router-link :to="cambiarVista('A')">Ayuntamientos</router-link>
                            </div>
                        </li>
                        <li>
                            <div
                              :class="{
                                'activo': this.$router.currentRoute.name === 'BaseDatos'
                              }"
                            >
                                <router-link to="/base-datos">
                                    <font-awesome-icon icon="database" class="mr-3" />
                                    Base de datos
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import ModoOscuro from '@/components/ModoOscuro.vue';
import Zoom from '@/components/Movil/componentes/Zoom.vue';

export default {
  components: { ModoOscuro, Zoom },
  methods: {
    cambiarVista(tipoEleccion) {
      return `/${tipoEleccion}/ENT/VPC`;
    },
  },
  computed: {
    eleccion() {
      return this.$route.params.eleccion;
    },
    esOscuro() {
      return this.$store.state.esOscuro;
    },
    esRepresentacionProporcional() {
      return this.$router.currentRoute.name === 'RepresentacionProporcional';
    },
  },
};
</script>
